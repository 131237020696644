<template>
  <div class="form-group">
    <label
      :class="[required ? 'required' : false]"
      v-if="label"
      :for="id || 'textarea_' + uid + _uid"
      >{{ label }}</label
    >
    <textarea
      v-bind="$attrs"
      v-on="listeners"
      :required="required"
      class="form-control"
      :value="value"
      :class="_class"
      :id="id || 'textarea_' + uid + _uid"
      @input="onInput($event)"
      @change="onChange($event)"
    />
    <transition name="fade">
      <small
        v-if="invalidMessage || validMessage || invalidFeedback || description"
        :id="'area-' + id"
        class="form-text text-muted"
        :class="[invalidFeedback || invalidMessage ? 'danger-color' : false]"
      >
        {{ invalidMessage || validMessage || invalidFeedback || description }}
      </small>
    </transition>
  </div>
</template>

<script>
import { inputProps as props } from "./input-props";
import { inputValidationMixin } from "@/components/input-mixins";

export default {
  name: "ATextarea",
  mixins: [inputValidationMixin],
  props: {
    ...props,
    label: String,
    _class: String,
    id: String,
    uid: {
      type: String,
      default: Date.now().toString()
    },
    description: String
  },
  computed: {
    listeners() {
      const { input, change, ...listeners } = this.$listeners; // eslint-disable-line no-unused-vars
      return listeners;
    }
  },
  methods: {
    onInput(e) {
      this.state = e.target.value;
      this.$emit("input", this.state, e);
      if (this.lazy === true) {
        return;
      }

      clearTimeout(this.syncTimeout);
      this.syncTimeout = setTimeout(
        () => {
          this.$emit("update:value", this.state, e);
        },
        this.lazy !== false ? this.lazy : 0
      );
    },
    onChange(e) {
      this.state = e.target.value;
      this.$emit("change", this.state, e);
      this.$emit("update:value", this.state, e);
    }
  }
};
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: var(--th-inputs-margin-buttom);

  textarea {
    border: 4px solid rgba(135, 149, 168, 10%);
    border-radius: 10px;
    padding: 16px 15px;
    font-size: 0.875rem;
    line-height: 1.0625rem;

    &::placeholder {
      color: rgba(143, 142, 148, 1);
    }
  }
}
</style>
